import React, { Component } from 'react'
import {Helmet} from 'react-helmet'
import ModelsViewComponent from '../../Components/ModelView/Loadable'
import Input from '../../Components/FormElements/Input/Loadable'

import { ModelViewContainer } from './style'

import {connect} from 'react-redux';
let actions = require('../../Actions');

class ModelsView extends Component {
    state={
        modelNameState: []
    }
    componentDidMount() {
        this.props.carListView();    
    }
   
    filterModels(e){
        const { modelName } = this.props.borzMotor;
        const findTo = e.target.value;
        findTo.length === 0 && this.setState({ modelNameState: [] })
        modelName && modelName.filter(car =>  car.model === findTo && this.setState({modelNameState: [car]}))
    }

    render() {
        const { modelNameState } = this.state;
        const { modelName } = this.props.borzMotor;
        return (
            <ModelViewContainer>
            <Helmet>
                <title>Araç Modelleri | Borz Motor</title>
            </Helmet>
            <div className="addToModelsViewLabel">
                <span>Ekli olan araç modelleri</span>
            </div>
            <div>
                <Input
                    type="text"
                    labelText="Modeller içinde ara"
                    inputID="forModels"
                    inputHtmlFor="forModels"
                    onChange={ (e) => this.filterModels(e) }
                />
            </div>
            <div className="allModelsContainer">
                {
                    modelNameState && modelNameState.length > 0 ?
                    modelNameState && modelNameState.map(( { _id, model } ) => (
                        <ModelsViewComponent key={_id}>
                            { model }
                        </ModelsViewComponent>
                    ))
                    :
                    modelName && modelName.length > 0 ?
                    modelName && modelName.map(( { _id, model } ) => (
                        <ModelsViewComponent key={_id}>
                            { model }
                        </ModelsViewComponent>
                    ))
                    : <div>Kayıtlı Araç Modeli Bulunamadı</div>
                    }
            </div>
            </ModelViewContainer> 
        )
    }
}
export default connect((state)=>{return state},actions)( ModelsView )
