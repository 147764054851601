import React, { Component } from 'react'
import {Helmet} from 'react-helmet'
import { BoxViewContainer } from './style'

import ModelViewComponent from '../../Components/ModelView/Loadable'
import Input from '../../Components/FormElements/Input/Loadable'

import {connect} from 'react-redux';
let actions = require('../../Actions');

class BrandsView extends Component {
    state={
        boxNameState: [],
        boxListState: []
    }
    componentDidMount() {
        this.props.carBoxListView();
    }

    filterBrands(e){
        const { boxList } = this.props.borzMotor;
        const findTo = e.target.value;
        findTo.length === 0 && this.setState({ boxNameState: [] })
        boxList && boxList.filter(car =>  car.box === findTo && this.setState({boxNameState: [car]}))
    }
    
    render() {
        const { boxList } = this.props.borzMotor;
        const { boxNameState } = this.state;
        return (
            <BoxViewContainer>
            <Helmet>
                <title>Araç Paketleri | Borz Motor</title>
            </Helmet>
            <div className="addToModelsViewLabel">
                <span>Ekli olan araç paketleri</span>
            </div>
            <div>
                <Input
                    type="text"
                    labelText="Paketler içinde ara"
                    inputID="forBox"
                    inputHtmlFor="forBox"
                    onChange={ (e) => this.filterBrands(e) }
                />
            </div>
            <div className="allModelsContainer">
                {
                    boxNameState && boxNameState.length > 0 ? 
                    boxNameState && boxNameState.map(( {_id, box} ) => (
                        box && <ModelViewComponent key={_id}>
                                        {box}
                                 </ModelViewComponent>
                    ))
                    :
                    boxList && boxList.length > 0 ? 
                    boxList && boxList.map(( {_id, box} ) => (
                        box && <ModelViewComponent key={_id}>
                                    {box}
                                 </ModelViewComponent>
                    ))
                    : <div>Kayıtlı Araç Paketi Bulunamadı</div>
                }
            </div>

            </BoxViewContainer>
        )
    }
}

export default connect((state)=>{return state},actions)(BrandsView)
