import styled from 'styled-components'

const AboutUsSeperator = styled.div `
        margin: 10px;
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        border: 1px #ededed solid;
    label{
        background-color: #3c7ec1;
        padding: 5px 10px;
        border-radius: 5px;
        margin: 0 10px 10px 0;
        display: inline-block;
        border-radius: 4px;
        border: 1px #ededed solid;
        color: #fff;
        font-size: 13px;
        text-transform: uppercase;
    }
`;

const AboutContainer = styled.form `
        display: grid;
        grid-template-columns: repeat(3,33.33%);
        @media screen and (min-width: 280px) and (max-width: 800px){
            grid-template-columns: repeat(1, 100%);
        } 
`;

export { AboutUsSeperator, AboutContainer };