import styled from 'styled-components'

const AllCar = styled.div `
    display: grid;
    grid-template-columns: repeat(4, 25%);
    @media screen and (min-width: 280px) and (max-width: 800px){
            grid-template-columns: repeat(1, 100%);
        } 
`;


const TotalAmount = styled.div `
        display: flex;
    justify-content: flex-end;
    margin: 10px;
    font-size: 12px;
    color: #0070ff;
  letter-spacing: -0.3px;
      strong{
      margin-right: 3px;
      }
`;


export { AllCar, TotalAmount }