import React, { Component } from 'react';
import { BrowserRouter, Redirect, Switch, Route} from "react-router-dom";
import { Link } from 'react-router-dom'

/* components */
import Header from './Components/Header/Loadable'
import SideMenu from './Components/SideMenu/Loadable'
import MobilMenu from './Components/MobileMenu/Loadable'
/* components */

/* pages */
import Login from './Containers/Login'
import Dashboard from './Containers/Dashboard'
import AddCar from './Containers/AddCar'
import CarBrandSave from './Containers/CarBrandSave'
import CarModelSave from './Containers/CarModelSave'
import CarMotorSave from './Containers/CarMotorSave'
import CarBoxSave from './Containers/CarBoxSave'
import CarsView from './Containers/CarsView'
import About from './Containers/About'
/* pages */

class App extends Component{
  
  constructor(props){
        super(props);
          this.state={
            menuControl: true
          }
          this.mobileMenuClick = this.mobileMenuClick.bind(this)
          this.logOut = this.logOut.bind(this)
  }

  mobileMenuClick(){
      const { menuControl } = this.state;
      this.setState({
          menuControl: !menuControl
      })
  }

  logOut(){
    localStorage.removeItem('token');
  }

  render(){
    const login = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const { menuControl } = this.state;
  return (
      <BrowserRouter>
            <Switch>
                {
                  login && (
                        <React.Fragment>
                          <header>
                              <div className="mobilMenuCloseIcon menuIcon" onClick={ this.mobileMenuClick }>
                              <div className="nav-icon">
                                <div></div>
                              </div>
                              </div>
                            <Header/>
                          </header>
                          <section>
                                {
                                  !menuControl && (
                                    <React.Fragment>
                                    <div className="mobilMenuCloseIcon" onClick={ this.mobileMenuClick }>
                                x
                              </div>
                              <div className="mobileMenu">
                                <MobilMenu>
                                    { username ? 
                                    <li style={{ 
                                      backgroundColor: '#567ea0',
                                      marginBottom: '10px',
                                      padding: '10px' }}>
                                        <span style={{
                                      display: 'block',
                                      margin: 'auto',
                                      width: '100%',
                                      textAlign: 'center',
                                      color: '#fff',
                                      textTransform: 'uppercase'
                                    }}>Hoşgeldin, <strong>{username}</strong></span></li>
                                    : 
                                    '' }
                                    <li onClick={this.mobileMenuClick}>
                                      <Link to="/">Anasayfa</Link>
                                    </li>
                                    <li onClick={this.mobileMenuClick}>
                                      <Link to="/arac-ekle">Araç Ekle</Link>
                                    </li>
                                    <li onClick={this.mobileMenuClick}>
                                      <Link to="/marka-kaydet">Marka Kaydet</Link>
                                    </li>                                      
                                    <li onClick={this.mobileMenuClick}>
                                      <Link to="/paket-kaydet">Paket Kaydet</Link>
                                    </li>                                      
                                    <li onClick={this.mobileMenuClick}>
                                      <Link to="/motor-kaydet">Motor Kaydet</Link>
                                    </li>                                      
                                    <li onClick={this.mobileMenuClick}>
                                      <Link to="/model-kaydet">Model Kaydet</Link>
                                    </li>                                      
                                    <li onClick={this.mobileMenuClick}>
                                      <Link to="/ilanlar">İlanlar</Link>
                                    </li>                                      
                                    <li onClick={this.mobileMenuClick}>
                                      <Link to="/hakkimizda">Hakkımızda</Link>
                                    </li>                                      
                                    <li onClick={this.mobileMenuClick}>
                                      <Link onClick={ this.logOut }>Çıkış Yap</Link>
                                    </li>                                      
                                </MobilMenu>
                              </div>
                                    </React.Fragment>
                                )}
                              <div className="sideMenu">
                                <SideMenu/>
                              </div>
                              <div className="content">
                                  <Route exact path="/" component={ Dashboard } />
                                  <Route exact path="/arac-ekle" component={ AddCar } />
                                  <Route exact path="/marka-kaydet" component={ CarBrandSave } />
                                  <Route exact path="/model-kaydet" component={ CarModelSave } />
                                  <Route exact path="/paket-kaydet" component={ CarBoxSave } />
                                  <Route exact path="/motor-kaydet" component={ CarMotorSave } />
                                  <Route exact path="/ilanlar" component={ CarsView } />
                                  <Route exact path="/hakkimizda" component={ About } />
                              </div>
                          </section>
                        </React.Fragment>
                  )
                }
                {
                  !login && (
                    <Route exact path="/" component={ Login } />
                  )
                }
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </BrowserRouter>
  );
}
}

export default App;
