import React, { Component } from 'react'
import {Helmet} from 'react-helmet'
import ModelsViewComponent from '../../Components/ModelView/Loadable'
import Input from '../../Components/FormElements/Input/Loadable'

import { MotorViewContainer } from './style'

import {connect} from 'react-redux';
let actions = require('../../Actions');

class ModelsView extends Component {
    state={
        motorNameState: []
    }
    componentDidMount() {
        this.props.carMotorListView();    
    }
   
    filterModels(e){
        const { motorList } = this.props.borzMotor;
        const findTo = e.target.value;
        findTo.length === 0 && this.setState({ motorNameState: [] })
        motorList && motorList.filter(car =>  car.motor === findTo && this.setState({motorNameState: [car]}))
    }

    render() {
        const { motorNameState } = this.state;
        const { motorList } = this.props.borzMotor;
        return (
            <MotorViewContainer>
            <Helmet>
                <title>Motor Hacimleri | Borz Motor</title>
            </Helmet>
            <div className="addToModelsViewLabel">
                <span>Ekli olan motor hacimleri</span>
            </div>
            <div>
                <Input
                    type="text"
                    labelText="Motor Hacimleri içinde ara"
                    inputID="forMotors"
                    inputHtmlFor="forMotors"
                    onChange={ (e) => this.filterModels(e) }
                />
            </div>
            <div className="allModelsContainer">
                {
                    motorNameState && motorNameState.length > 0 ?
                    motorNameState && motorNameState.map(( { _id, motor } ) => (
                        <ModelsViewComponent key={_id}>
                            { motor }
                        </ModelsViewComponent>
                    ))
                    :
                    motorList && motorList.length > 0 ?
                    motorList && motorList.map(( { _id, motor } ) => (
                        <ModelsViewComponent key={_id}>
                            { motor }
                        </ModelsViewComponent>
                    ))
                    : <div>Kayıtlı Motor Hacmi Bulunamadı</div>
                    }
            </div>
            </MotorViewContainer> 
        )
    }
}
export default connect((state)=>{return state},actions)( ModelsView )
