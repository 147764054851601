import React, { Component } from 'react'
import Input from '../../Components/FormElements/Input/Loadable'
import Selected from '../../Components/FormElements/Select/Loadable'
import TextEditor from '../../Components/FormElements/TextEditor/Loadable'
import ButtonComponent from '../../Components/FormElements/Button/Loadable'
import Checkbox from '../../Components/FormElements/Checkbox/Loadable'
import { development } from '../../config'
import axios from 'axios'
import { InputContainer, SelectContainer, InputContainerDate, InputContainerSoldDate, TextAreaContainer, ButtonContainer, InputPlakaContainer, Cost, RadioInputGroup, RadioButtonHeading, Pending } from './style'
import Load from '../../Utils/Loader'
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'; // Crops image
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import { DatePicker } from 'antd';
import moment from 'moment';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2'

import { tramerData } from '../../Utils/tramerType'
import {connect} from 'react-redux';

registerPlugin(FilePondPluginImageCrop, FilePondPluginImagePreview, FilePondPluginImageResize, FilePondPluginImageTransform);

let actions = require('../../Actions');


class CarsUpdate extends Component {
    constructor(props){
        super(props);
        this.state={
            _id: '',
            published: null, 
            kilometers: '',
            province: '',
            city: '',
            price: '', 
            fuel: '', 
            gear: '',
            intro: '',
            settings: '',
            description: '',
            year: '',
            responsible: '',
            kasko: '',
            sellPrice: '',
            purchaseDate: null,
            color: '',
            soldPrice: '',
            responsiblePhone: '',
            activeCarInfo: {},
            isSubmit: false,
            images: [],
            warning: '',
            plaka: '',
            tramer: '',
            cost: '',
            instaVideo: '',
            motor: '',
            sellingDate: null,
            box: '',
            painting: {},
            pending: false,
            showCase: ''
        }
        this.fileChange = this.fileChange.bind(this)
        this.vehicleUpdate = this.vehicleUpdate.bind(this)
        this.tramerControl = this.tramerControl.bind(this)
        this.checkboxFunction = this.checkboxFunction.bind(this)
        this.inputChange = this.inputChange.bind(this)
        this.selectedChange = this.selectedChange.bind(this)
        this.textEditor = this.textEditor.bind(this)
        this.textSettingsEditor = this.textSettingsEditor.bind(this)
        this.onChangeDatePicker = this.onChangeDatePicker.bind(this)
        this.purchaseDateonChangeDatePicker = this.purchaseDateonChangeDatePicker.bind(this)
    }
    componentDidMount() {
        localStorage.setItem('carUpdate', false)
        const { id } = this.props;
        this.props.carUpdateGetId(id);
        this.props.carMotorListView();
        this.props.carBoxListView();
        this.setState({
            sellingDate: null
        })
    }
    componentDidUpdate(prevProps, prevState){
        const { updateCarId } = this.props.borzMotor;
        if(prevProps.borzMotor.updateCarId !== updateCarId){
        this.setState({ 
            _id: updateCarId._id,
            brand: updateCarId.brand,
            published: updateCarId.published,
            model: updateCarId.model,
            kilometers: updateCarId.kilometers,
            province: updateCarId.province,
            city: updateCarId.city,
            sellPrice: updateCarId.sellPrice,
            soldPrice: updateCarId.soldPrice,
            purchaseDate: new Date(updateCarId.purchaseDate),
            sellingDate: new Date(updateCarId.sellingDate),
            color: updateCarId.color,
            price: updateCarId.price,
            fuel: updateCarId.fuel,
            plaka: updateCarId.plaka,
            gear: updateCarId.gear,
            kasko: updateCarId.kasko,
            cost: updateCarId.cost,
            motor: updateCarId.motor,
            box: updateCarId.box,
            intro: updateCarId.intro,
            settings: updateCarId.settings,
            description: updateCarId.description,
            year: updateCarId.year,
            responsible: updateCarId.responsible,
            responsiblePhone: updateCarId.responsiblePhone,
            tramer: updateCarId.tramer,
            painting: updateCarId.painting,
            showCase: updateCarId.showCase,
            instaVideo: updateCarId.instaVideo,
         })
        }
    }
    onChangeDatePicker(date, dateString) {
        this.setState({
            sellingDate: dateString,
        });
      }

    purchaseDateonChangeDatePicker(date, dateString) {
        this.setState({
            purchaseDate: dateString,
        });
    }

    vehicleUpdate(e){
        e.preventDefault();
        const { isSubmit } = this.state;
                this.setState({
                    isSubmit: !isSubmit,
                    pending: true
                }, () => {
                    /* after setstate */

                    localStorage.setItem('carUpdate', true);
                    let addCarSaveUrl = `${development}/api/vehicle/update`;
                    const { _id, brand, published, model, kilometers, province, city, purchaseDate, color, sellPrice, soldPrice, plaka, cost, sellingDate, price, fuel, gear, kasko, motor, box, intro, settings, description, year, responsible, responsiblePhone, tramer, painting, images, showCase, instaVideo } = this.state;
                    const data = new FormData();
                    data.append('_id', _id);
                    data.append('brand', brand);
                    data.append('published', published);
                    data.append('model', model);
                    data.append('kilometers', kilometers);
                    data.append('province', province);
                    data.append('sellPrice', sellPrice);
                    data.append('soldPrice', soldPrice);
                    data.append('motor', motor);
                    data.append('color', color);
                    data.append('purchaseDate', purchaseDate);
                    data.append('box', box);
                    data.append('city', city);
                    data.append('price', price);
                    data.append('fuel', fuel);
                    data.append('gear', gear);
                    data.append('intro', intro);
                    data.append('plaka', plaka);
                    data.append('cost', cost);
                    data.append('settings', settings);
                    data.append('description', description);
                    data.append('kasko', kasko);
                    data.append('year', year);
                    data.append('responsible', responsible);
                    data.append('responsiblePhone', responsiblePhone);
                    data.append('sellingDate', sellingDate);
                    data.append('tramer', tramer);
                    data.append('painting', JSON.stringify(painting));
                    data.append('instaVideo', instaVideo);
                    data.append('showCase', showCase);
                    if(images.length > 0){
                        for( let i = 0; i < images.length; i++ ){
                            data.append("images", images[i], images[i].name);
                        }
                    }
                    axios.put(addCarSaveUrl, data)
                    .then(response => {
                        if(response.status === 200){
                            this.setState({
                                pending: false
                          }, () => {
                              window.location.replace("/ilanlar");
                              this.props.carUpdate(data);
                          });
                        }else{
                            return Swal.fire({
                              title: 'Kaydedilemedi! Tekrar Deneyin.',
                              imageUrl: 'https://unsplash.it/400/200',
                              imageWidth: 400,
                              imageHeight: 200
                            })
                        }
                      });

                    /* after setstate */
                });
    }

    tramerControl(e){
        const target = e.target;
        const id = target.dataset.id;
        const name = target.name;

        this.setState((prevState) => ({
            painting: {...prevState.painting, 
                [name]: id,
            }
        }));
    }

    inputChange(e){
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        if(name === "intro" && value.length === 70){
            this.setState({ warning: '70 karakter sınırını geçemezsiniz' })
        }else{
            this.setState({warning: ''})  
        }
    }

    inputFocus(e){
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState(prevState => {
            let activeCarInfo = Object.assign({}, prevState.activeCarInfo);  
            activeCarInfo.name = value;                     
            this.setState({ [name]: activeCarInfo.name })                                 
            return { activeCarInfo };
          })

    }

    selectedChange(e){
        const target = e;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        })
    }

    textSettingsEditor(event, editor){
        const data = editor.getData();
        this.setState({
            settings: data
        })
    }

    textEditor(event, editor){
            const data = editor.getData();
            this.setState({
                description: data
            })
    }

    fileChange(e){
        e.preventDefault();
        let files = e.target.files;
        this.setState({
            images: files
        }) 
    }

    checkboxFunction(e, data){
        let published = data.checked;
        this.setState(prevState => {
            let activeCarInfo = Object.assign({}, prevState.activeCarInfo);  
            activeCarInfo.published = published;                     
            this.setState({ published: activeCarInfo.published })                                 
            return { activeCarInfo };
          })
    }

    render() {
        const { 
            warning,
            painting,
            published,
            kilometers,
            province,
            city,
            price,
            fuel,
            gear,
            intro,
            settings,
            soldPrice,
            sellPrice,
            //description,
            year,
            plaka,
            purchaseDate,
            color,
            responsible,
            responsiblePhone,
            tramer,
            cost,
            motor,
            sellingDate,
            box,
            kasko,
            instaVideo,
            pending
         } = this.state;
        const { submitModal } = this.props;
        const defaultMaskOptions = {
            prefix: '',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ',',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2, // how many digits allowed after the decimal
            integerLimit: 7, // limit length of integer numbers
            allowNegative: false,
            allowLeadingZeroes: false,
          }

          const currencyMask = createNumberMask(defaultMaskOptions)

        const { boxList, motorList } = this.props.borzMotor;
        const yakit = [
            { value: 'Benzin', label: 'Benzin', name: 'fuel' },
            { value: 'Dizel', label: 'Dizel', name: 'fuel' },
            { value: 'Lpg & Benzin', label: 'Lpg & Benzin', name: 'fuel' },
            { value: 'Hybrit', label: 'Hybrit', name: 'fuel' }
          ];
          const yearSelect = [
            { value: '2000', label: '2000', name: 'year' },
            { value: '2001', label: '2001', name: 'year' },
            { value: '2002', label: '2002', name: 'year' },
            { value: '2003', label: '2003', name: 'year' },
            { value: '2004', label: '2004', name: 'year' },
            { value: '2005', label: '2005', name: 'year' },
            { value: '2006', label: '2006', name: 'year' },
            { value: '2007', label: '2007', name: 'year' },
            { value: '2008', label: '2008', name: 'year' },
            { value: '2009', label: '2009', name: 'year' },
            { value: '2010', label: '2010', name: 'year' },
            { value: '2011', label: '2011', name: 'year' },
            { value: '2012', label: '2012', name: 'year' },
            { value: '2013', label: '2013', name: 'year' },
            { value: '2014', label: '2014', name: 'year' },
            { value: '2015', label: '2015', name: 'year' },
            { value: '2016', label: '2016', name: 'year' },
            { value: '2017', label: '2017', name: 'year' },
            { value: '2018', label: '2018', name: 'year' },
            { value: '2019', label: '2019', name: 'year' },
            { value: '2020', label: '2020', name: 'year' },
          ];
        const vites = [
            { value: 'Manuel', label: 'Manuel', name: 'gear' },
            { value: 'Yarı Otomatik', label: 'Yarı Otomatik', name: 'gear' },
            { value: 'Otomatik', label: 'Otomatik', name: 'gear' }
          ];

        const boxs = boxList && boxList.map(({ box }) => {
            return { value: box, label: box, name: 'box' };
        });

        const motors = motorList && motorList.map(({ motor }) => {
            return { value: motor, label: motor, name: 'motor' };
        });
        return (
            <React.Fragment>
                { pending && <React.Fragment><Pending><Load/><span>İlan Kaydediliyor, lütfen bekleyiniz.</span></Pending></React.Fragment> }
            <form onSubmit={ (e) => this.vehicleUpdate(e) }>

                {
                    !published &&
                    <InputContainerDate>
                        <div>
                            <p>Aracın ilk alındığı tarih verisini giriniz. <strong>İlan tarihinden bağımsızdır, araç envantere girdiği tarihi girmelisiniz. </strong> </p>
                        </div>
                        <DatePicker value={purchaseDate && purchaseDate ? moment(purchaseDate) : null} placeholder="Aracın alındığı tarihi seçin" onChange={this.purchaseDateonChangeDatePicker} />
                    </InputContainerDate>
                }

                { 
                    !published && 
                    <InputContainerSoldDate>
                        <div style={{ display: 'flex' }}>
                        <strong>Satış Tarihi: </strong>
                        <p>Bu alandaki tarih verisini araç noter satışı olduktan sonra girmelisiniz.</p>
                        </div>
                        <DatePicker value={sellingDate && sellingDate ? moment(sellingDate) : null} placeholder="satış tarihi seçin" onChange={this.onChangeDatePicker} />
                    </InputContainerSoldDate>
                 }
            <InputContainer>
            <Checkbox 
                        label="İlanı yayınla veya kaldır"
                        checked={published}
                        onChange={this.checkboxFunction}
            />

            <FilePond 
                ref={ref => (this.pond = ref)}
                allowMultiple={true}
                maxFileSize="60MB"
                name="images"
                allowImageCrop={true}
                labelIdle="Bir Dosya Seçin ya da Dosyayı Sürükleyip Bırakın!"
                allowImageTransform={true}
                imageCropAspectRatio={'3:2'}
                allowImageResize={true}
                imageResizeTargetHeight={600}
                onpreparefile={ (fileItem, file) => {
                    let joined = this.state.images.concat(file)
                    this.setState({
                        images: joined
                      });
                  }}
                  onactivatefile={(e) => {
                    const getEl = document.querySelectorAll('.filepond--file-info-main');
                    for (let i = 0; i < getEl.length; i++) {
                      if(getEl[i].innerText === e.file.name){
                          getEl[i].style.cssText = "color: #34d234; font-size: 1em; font-weight: 900;";
                      }else{
                          getEl[i].removeAttribute("style");
                      } 
                    }
                    this.setState({
                      showCase: e.file.name
                    })
                }}
                
            />

            </InputContainer>
                <InputPlakaContainer>
                    <Input
                        labelText="Araç Plakası"
                        type="text"
                        placeholder="Dikkat!! Benzersiz bir değer olmalıdır, Araç kart görüntüsünde sadece plaka ile görüntülenebilecektir."
                        name="plaka"
                        value={plaka || ''}
                        inputID="plaka"
                        inputHtmlFor="plaka"
                        onChange={ (e) => this.inputChange(e) }
                    />
                </InputPlakaContainer>
            <InputContainer>
                <Input
                        labelText="Araç Sorumlu Kişisi"
                        type="responsible"
                        name="responsible"
                        value={responsible || '' }
                        inputID="responsible"
                        inputHtmlFor="responsible"
                        onChange={ (e) => this.inputChange(e) }
                    />
                <Input
                        labelText="Sorumlu Kişi Telefon"
                        type="text"
                        name="responsiblePhone"
                        value={responsiblePhone || ''}
                        inputID="responsiblePhone"
                        inputHtmlFor="responsiblePhone"
                        onChange={ (e) => this.inputChange(e) }
                    />
            </InputContainer>
            <InputContainer>
                <Input
                        labelText="Bulunduğu İl"
                        type="text"
                        name="city"
                        value={city || ''}
                        inputID="city"
                        inputHtmlFor="city"
                        onChange={ (e) => this.inputChange(e) }
                    />
                <Input
                        labelText="Bulunduğu İlçe"
                        type="text"
                        name="province"
                        value={province || ''}
                        inputID="province"
                        inputHtmlFor="province"
                        onChange={ (e) => this.inputChange(e) }
                    />
            </InputContainer>
            <InputContainer>
                <Input 
                        labelText="Kilometre"
                        type="text"
                        name="kilometers"
                        value={kilometers || ''}
                        inputID="kilometers"
                        inputHtmlFor="kilometers"
                        onChange={ (e) => this.inputChange(e) }
                />
                <Input 
                        labelText="Fiyat"
                        type="text"
                        name="price"
                        value={price || ''}
                        inputID="price"
                        inputHtmlFor="price"
                        onChange={ (e) => this.inputChange(e) }
                />
            </InputContainer>
            <InputContainer>
                <Input 
                    labelText={ warning !== '' ? warning : "Kısa açıklama" }
                    type="text"
                    name="intro"
                    maxLength="70"
                    value={intro || ''}
                    labelStyle={ warning !== '' ? {color: '#ad4b4b'} : {}}
                    inputID="intro"
                    inputHtmlFor="intro"
                    onChange={ (e) => this.inputChange(e) }
                />
                  <Input
                    labelText={ warning !== '' ? warning : "Tramer Bilgisi" }
                    type="text"
                    name="tramer"
                    value={tramer || ''}
                    labelStyle={ warning !== '' ? {color: '#ad4b4b'} : {}}
                    inputID="tramer"
                    inputHtmlFor="tramer"
                    onChange={ (e) => this.inputChange(e) }
                  />
            </InputContainer>
            <InputContainer>
                    <Input
                    labelText={ warning !== '' ? warning : "Instagram Test Sürüşü Videosu" }
                    type="text"
                    name="instaVideo"
                    value={instaVideo || ''}
                    placeholder="örneğin; 'B-pFRNeFcTf' gibi bir kod girin "
                    labelStyle={ warning !== '' ? {color: '#ad4b4b'} : {}}
                    inputID="instaVideo"
                    inputHtmlFor="Instagram Test Sürüşü Videosu"
                    onChange={ (e) => this.inputChange(e) }
                            />
                    <Input
                    labelText={ warning !== '' ? warning : "Kasko Bedeli" }
                    type="text"
                    name="kasko"
                    value={kasko || ''}
                    placeholder="Kasko Bedeli"
                    labelStyle={ warning !== '' ? {color: '#ad4b4b'} : {}}
                    inputID="kasko"
                    inputHtmlFor="kasko"
                    onChange={ (e) => this.inputChange(e) }
                            />
            </InputContainer>
                <InputContainer>
                    <Input
                        labelText={ warning !== '' ? warning : "Araç Rengi" }
                        type="text"
                        name="color"
                        value={color || ''}
                        placeholder="Araç Rengi"
                        labelStyle={ warning !== '' ? {color: '#ad4b4b'} : {}}
                        inputID="color"
                        inputHtmlFor="color"
                        onChange={ (e) => this.inputChange(e) }
                    />
                </InputContainer>
            <SelectContainer>
                <Selected 
                onChange={ (e) => this.selectedChange(e) } 
                value={ {value: fuel, label: fuel} } 
                name="fuel" 
                placeholder="Yakıt Seçimi" 
                options={yakit}
                    />
                <Selected 
                onChange={ (e) => this.selectedChange(e) } 
                value={{value: gear, label: gear}} 
                name="gear" 
                placeholder="Vites Seçimi" 
                options={vites}
                    />
                <Selected 
                onChange={ (e) => this.selectedChange(e) } 
                value={{value: year, label: year}} 
                name="year" 
                placeholder="Araç Yılı" 
                options={yearSelect}
                    />
            </SelectContainer>
            <SelectContainer>
            <Selected value={{value: motor, label: motor}} onChange={ (e) => this.selectedChange(e) } name="motor" placeholder="Aracın Motor Hacmi" options={motors} />
            <Selected value={{value: box, label: box}} onChange={ (e) => this.selectedChange(e) } name="box" placeholder="Araç Paketi" options={boxs} />
            </SelectContainer>
            <TextAreaContainer>
                    <RadioInputGroup>
                   <div className="RadioInputGroup">
                   <RadioButtonHeading>
                        <li>Tramer Alanı</li>
                        <li>Boyasız</li>
                        <li>Boyalı</li>
                        <li>Lokal Boyalı</li>
                        <li>Değişen</li>
                    </RadioButtonHeading>
                   </div>

                    {
                        tramerData.length > 0 && tramerData.map(({ type, text, name, tramer }, i) => (
                            <div className="RadioInputGroup" key={i}>
                            <span>{text}</span>
                            {
                                    tramer.length > 0 && tramer.map(( piece, i ) => {
                                        return <div className="radioButtonContainer" id={name} key={i}>
                                            <Input
                                                key={i}
                                                dataId={piece.name}
                                                checked={ painting && painting[name] === piece.name }
                                                value={name || ''}
                                                type={type}
                                                name={name}
                                                onChange={ (e) => this.tramerControl(e) }
                                            />
                                        </div>
                                    })
                                }
                            </div>
                        ))
                    }
                  
                    </RadioInputGroup>
                </TextAreaContainer>
            <TextAreaContainer>
                <TextEditor 
                    onChange={ ( event, editor ) => this.textSettingsEditor(event, editor) }
                    data={settings}
                />
            </TextAreaContainer>
                <Cost>
                    <div className="costGroup">
                        <label htmlFor="cost">Araç Alış Fiyatı</label>
                        <p> Aracın alış fiyatını girmelisiniz. </p>
                        <MaskedInput
                            name="sellPrice"
                            id="sellPrice"
                            value={sellPrice || ''}
                            onChange={ (e) => this.inputChange(e) }
                            mask={currencyMask}
                            placeholder="Aracın Alış Fiyatını Giriniz.."
                        />
                    </div>
                    <div className="costGroup">
                        <label htmlFor="cost">Aracın Satış Fiyatı</label>
                        <p> Bu alanda Aracın satıldığı net rakamı giriniz. </p>
                        <MaskedInput
                            name="soldPrice"
                            id="soldPrice"
                            value={soldPrice || ''}
                            onChange={ (e) => this.inputChange(e) }
                            mask={currencyMask}
                            placeholder="Bu alanda Aracın Satıldığı Net Rakam Girilmelidir."
                        />
                    </div>
                    <div className="costGroup">
                        <label htmlFor="cost">Araç Masraf Girişi</label>
                        <p> Bu alanda Araç masraflarının toplamını girmelisiniz. <br/> Kaporta Düzeltme (1000 TL) + Pasta / Cila (500 TL) ise 1500 olarak girmelisiniz. <br/> Herhangi bir <strong>Noktalama işareti vs girmeyiniz.</strong> </p>
                        <MaskedInput
                            name="cost"
                            id="cost"
                            value={cost || ''}
                            onChange={ (e) => this.inputChange(e) }
                            mask={currencyMask}
                            placeholder="Bu alan, araca yapılan toplam masraf tek kalemde girilmelidir. Örnek; '10000' "
                        />
                    </div>
                </Cost>
            <ButtonContainer>
                <ButtonComponent
                    type="submit"
                    label="Kaydet"
                    submitModal={ submitModal }
                 />
            </ButtonContainer>
            </form>
            </React.Fragment>
        )
    }
}
export default connect((state)=>{return state},actions)( CarsUpdate )
