import styled from 'styled-components'

const InputContainer = styled.div `
    display: grid;
    grid-template-columns: repeat(2, 50%);
    padding: 10px;
    margin-bottom: 20px;
    .group{
        margin: 0!important;
            input[type=file]{
                border-bottom: none!important;
            }
    }
            .ui.checkbox{
                display: flex;
                align-items: center;
            }
`;

const InputContainerSoldDate = styled.div `
    display: grid;
    grid-template-columns: repeat(2, 50%);    
        margin-bottom: 20px;
    background: #f1f0ef;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 5px;
    width: 100%;
    max-width: 850px;
    padding: 10px;
    .group{
        margin: 0!important;
            input[type=file]{
                border-bottom: none!important;
            }
    }
            .ui.checkbox{
                display: flex;
                align-items: center;
            }
`;

const InputContainerDate = styled.div`
    margin-bottom: 20px;
    background: #f1f0ef;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 5px;
        width: 100%;
    max-width: 850px;
    padding: 10px;
    div{
      margin: 10px;
    }
      span{
      display: block;
      width: 100%;
      }
`

const InputPlakaContainer = styled.div `
    display: grid;
    grid-template-columns: repeat(1, 100%);
    padding: 30px 10px;
    margin-bottom: 20px;
    background: #f1f0ef;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 5px;
    .group{
        margin: 0!important;
            input[type=file]{
                border-bottom: none!important;
            }
            input{
              width: 100%;
            }
    }
            .ui.checkbox{
                display: flex;
                align-items: center;
            }
            @media screen and (min-width: 280px) and (max-width: 800px){
                grid-template-columns: repeat(1, 100%);
                margin-bottom: 0;
            } 
`;

const SelectContainer = styled.div `
display: grid;
grid-template-columns: repeat(2, 50%);
padding: 10px;
`;

const Cost = styled.div `
display: grid;
grid-template-columns: repeat(1, 100%);
padding: 10px;
    background-color: #f1f0ef;
    margin: 10px;
    border-radius: 5px;
    border: 1px dashed #cecece;
    .costGroup{
    padding: 10px;
    border: 1px #e4e4e4 dashed;
    margin: 5px 0;
    border-radius: 5px;
    background-color: #38242430;
                input{
                width: 100%!important;
                border: none;
                border-radius: 5px;
    padding: 10px;
    margin-top: 12px;
            }
            p{
                margin-top: 10px;
                font-style: italic;
            }
    }
`;

const TextAreaContainer = styled.div `
margin-top: 20px;
padding: 10px;
`;

const ButtonContainer = styled.div `
margin-top: 10px;
padding: 10px;
div{
    display: flex;
    justify-content: flex-end;
}
`;

const ImageContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(8,12.5%);
    text-align: center;
    grid-row-gap: 10px;
    margin-bottom: 40px;
    background-color: aliceblue;
    padding: 10px;
    border-radius: 5px;
    border: 1px #ededed solid;
img{
    width: 100px;
}
`;


const RadioInputGroup = styled.div`
    background-color: #fff;
    padding: 10px;
    border: 1px #ededed solid;
    border-radius: 4px;
    .RadioInputGroup{
        display: flex;
        align-items:center;
        &:first-child{
            border-bottom: 1px #ededed solid;
        }
        .radioButtonContainer{
            max-width: 150px;
            width: 100%;
            margin: auto;
                .group{
                    margin: 25px 0;
                    input{
                        margin: auto;
                    }
                    .bar{
                        &:before{
                            content: none!important;
                        }
                    }
                }
        }
        span{
            display: block;
            width: 100%;
            max-width: 250px;
            text-align: left;
            text-transform: capitalize;
        }
    }
`;

const RadioButtonHeading = styled.ul`
display: flex;
width: 100%;
text-align:center;
margin: 0;
    li{
        display: block;
    width: 100%;
    max-width: 150px;
    margin: auto;
    margin-left: 0;
    background-color: #ededed;
    padding: 5px;
    border-radius: 4px;
    border-bottom-left-radius:0;
    border-bottom-right-radius:0;
        &:first-child{
            max-width: 250px;
            margin: 0;
            display: inline-flex;
            font-weight: 700;
            background-color: transparent;
        }
    }
`;


const Pending = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0, .9);
    z-index:999999999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
        span{
            margin-top: 10px;
            font-size: 26px;
            color: #fff;
        }
`;

export { InputContainer, SelectContainer, InputContainerDate, InputContainerSoldDate, Cost, TextAreaContainer, ButtonContainer, InputPlakaContainer, ImageContainer, RadioInputGroup, RadioButtonHeading, Pending }