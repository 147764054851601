import styled from 'styled-components'

const ModelSaveContainer = styled.div `
    form{
        padding: 10px;
        margin:45px 0;
        div{
                &:first-child{
                    margin: 0!important;
                }
            .group{
                margin:0!important;
            }
        }
    }
`;

export { ModelSaveContainer }