import axios from 'axios';
import { makeRequest } from '../Utils/Request'
import { development } from  '../config'

const requestOptions = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };

export const addModelDataCreator = (model) => {
    return {
        type:'ADDCARMODEL',
        model
    }
}

export const addBrandCreator = (brand) => {
    return {
        type:'ADDBRAND',
        brand, 
    }
}

export const addMotorCreator = (motor) => {
    return {
        type:'ADDMOTOR',
        motor, 
    }
}

export const addBoxCreator = (box) => {
    return {
        type:'ADDBOX',
        box, 
    }
}

export const carBrandListCreator = (brandList) => {
    return {
        type:'VIEWCARBRAND',
        brandList
    }
}

export const activeVehicleActionCreator = (active) => {
    return {
        type:'ACTIVEVEHICLE',
        active
    }
}

export const deActiveVehicleActionCreator = (deActive) => {
    return {
        type:'DEACTIVEVEHICLE',
        deActive
    }
}

export const carBoxListCreator = (boxList) => {
    return {
        type:'VIEWCARBOX',
        boxList
    }
}

export const carMotorListCreator = (motorList) => {
    return {
        type:'VIEWCARMOTOR',
        motorList
    }
}

export const loginCreator = (login) => {
    return {
        type:'LOGIN',
        login
    }
}

export const carUpdateCreator = (updateCarInfos) => {
    return {
        type:'CARUPDATE',
        updateCarInfos
    }
}

export const allCarListCreator = (allCarList) => {
    return {
        type:'VIEWALLCAR',
        allCarList
    }
}

export const carUpdateGetIdCreator = (updateCarId) => {
    return {
        type:'CARUPDATEGETBYID',
        updateCarId
    }
}

export const addCarSaveCreator = (carDetails) => {
    return {
        type:'ADDCARSAVE',
        carDetails
    }
}

export const addCarSavePending = (carSavePending) => {
    return {
        type:'CARSAVEPENDING',
        carSavePending
    }
}

export const addCarSaveError = (carSaveError) => {
    return {
        type:'CARSAVEERROR',
        carSaveError
    }
}

export const carDeleteCreator = (carDelete, carDeleteSuccess, carDeleteData) => {
    return {
        type:'DELETECAR',
        carDelete, 
        carDeleteSuccess,
        carDeleteData
    }
}

export const aboutDescriptionCreator = (aboutParam) => {
    return {
        type:'ABOUT',
        aboutParam
    }
}

export const getAboutCreator = (getAboutParam) => {
    return {
        type:'GETABOUT',
        getAboutParam
    }
}

export const viewModelDataCreator = (modelName) => {
    return {
        type:'VIEWCARMODEL',
        modelName
    }
}

export const addCarModel = (model) => {
    let addModel = `${development}/api/addModel`;
    return (dispatch) => {
         axios.post(addModel, {model}, requestOptions).then((res) => {
                const addModelData = res.data;
                dispatch(addModelDataCreator(addModelData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const addBrand = (brand) => {
    let addBrandUrl = `${development}/api/addBrand`;
    return (dispatch) => {
         axios.post(addBrandUrl, { brand }, requestOptions).then((res) => {
                const addBrandData = res.data;
                dispatch(addBrandCreator(addBrandData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const addBox = (box) => {
    let addBoxUrl = `${development}/api/addBox`;
    return (dispatch) => {
         axios.post(addBoxUrl, { box }, requestOptions).then((res) => {
                const addBoxData = res.data;
                dispatch(addBoxCreator(addBoxData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const addMotor = (motor) => {
    let addMotorUrl = `${development}/api/addMotor`;
    return (dispatch) => {
         axios.post(addMotorUrl, { motor }, requestOptions).then((res) => {
                const addMotorData = res.data;
                dispatch(addMotorCreator(addMotorData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const carListView = () => {
    let viewModelUrl = `${development}/api/allModelView`;
    return (dispatch) => {
         axios.get(viewModelUrl).then((res) => {
                const viewModelData = res.data;
                dispatch(viewModelDataCreator(viewModelData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const carBrandListView = () => {
    let carBrandListUrl = `${development}/api/allBrandsView`;
    return (dispatch) => {
         axios.get(carBrandListUrl).then((res) => {
                const carBrandListData = res.data;
                dispatch(carBrandListCreator(carBrandListData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const activeVehicle = () => {
    let ActiveVehicleUrl = `${development}/api/activeVehicle`;
    return (dispatch) => {
        axios.get(ActiveVehicleUrl).then((res) => {
                const ActiveVehicleUrlData = res.data;
                dispatch(activeVehicleActionCreator(ActiveVehicleUrlData))
            },
            (err) => {
                console.log(err);
            }
        )
    }
}

export const deActiveVehicle = () => {
    let deActiveVehicleUrl = `${development}/api/deActiveVehicle`;
    return (dispatch) => {
        axios.get(deActiveVehicleUrl).then((res) => {
                const deActiveVehicleUrlData = res.data;
                dispatch(deActiveVehicleActionCreator(deActiveVehicleUrlData))
            },
            (err) => {
                console.log(err);
            }
        )
    }
}

export const carBoxListView = () => {
    let carBoxListUrl = `${development}/api/allBoxView`;
    return (dispatch) => {
         axios.get(carBoxListUrl).then((res) => {
                const carBoxListData = res.data;
                dispatch(carBoxListCreator(carBoxListData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const carMotorListView = () => {
    let carMotorListUrl = `${development}/api/allMotorView`;
    return (dispatch) => {
         axios.get(carMotorListUrl).then((res) => {
                const carMotorListData = res.data;
                dispatch(carMotorListCreator(carMotorListData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const login = (username, password) => {
    let loginUrl = `${development}/api/user/login`;
    let urlencoded = new URLSearchParams();
    urlencoded.append('username', username);
    urlencoded.append('password', password);
    return (dispatch) => {
    makeRequest("POST", loginUrl, urlencoded, requestOptions).then((res) => {
    const loginDataParse = JSON.parse(res);
    const loginData = loginDataParse;
    dispatch(loginCreator(loginData))
    },
    (err) => {
        console.log(err);
    })
    }
}

export const allCarListView = () => {
    let allCarListUrl = `${development}/api/allVehicleViews`;
    return (dispatch) => {
         axios.get(allCarListUrl).then((res) => {
                const allCarListData = res.data;
                dispatch(allCarListCreator(allCarListData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}

export const carUpdateGetId = ( updateCarId ) => {
    let carUpdateUrl = `${development}/api/carIdUpdate?_id=${updateCarId}`;
    return (dispatch) => {
        axios.get(carUpdateUrl).then((res) => {
            const getUpdateData = res.data;
            dispatch(carUpdateGetIdCreator(getUpdateData))
                    },
            (err) => {
                console.log(err);
            })
    }
}

export const carDelete = (_id) => {
    let carDeleteUrl = `${development}/api/vehicle/remove`;
    return (dispatch) => {
        let urlencoded = new URLSearchParams();
        urlencoded.append('_id', _id);

        makeRequest('DELETE', carDeleteUrl, urlencoded, requestOptions).then((res) => {
            let carData = JSON.parse(res);
            const CarDeleteMessage = carData.message;
            const CarDeleteSuccess = carData.success;
            const CarDeleteData = carData.data;
            dispatch(carDeleteCreator(CarDeleteMessage, CarDeleteSuccess, CarDeleteData))
                },
            (err) => {
                console.log(err);
            }
                 )}
}

export const carUpdate = ({ _id, published, kilometers, province, city, price, sellPrice, purchaseDate, color, soldPrice, kasko, plaka, sellingDate, fuel, gear, intro, settings, description, year, responsible, responsiblePhone, images, tramer, instaVideo, painting }) => {
    let carUpdateUrl = `${development}/api/vehicle/update`;
    return (dispatch) => {
        let urlencoded = new FormData();
        urlencoded.append('_id', _id);
        urlencoded.append('published', published);
        urlencoded.append('kilometers', kilometers);
        urlencoded.append('province', province);
        urlencoded.append('purchaseDate', purchaseDate);
        urlencoded.append('color', color);
        urlencoded.append('city', city);
        urlencoded.append('sellPrice', sellPrice);
        urlencoded.append('soldPrice', soldPrice);
        urlencoded.append('price', price);
        urlencoded.append('fuel', fuel);
        urlencoded.append('gear', gear);
        urlencoded.append('intro', intro);
        urlencoded.append('settings', settings);
        urlencoded.append('plaka', plaka);
        urlencoded.append('kasko', kasko);
        urlencoded.append('description', description);
        urlencoded.append('year', year);
        urlencoded.append('responsible', responsible);
        urlencoded.append('responsiblePhone', responsiblePhone);
        urlencoded.append('tramer', tramer);
        urlencoded.append('sellingDate', sellingDate);
        urlencoded.append('instaVideo', instaVideo);
        urlencoded.append('painting', JSON.stringify(painting));
        
        for( let i = 0; i < images.length; i++ ){
            urlencoded.append('images', images[i]);
        }
            makeRequest('PUT', carUpdateUrl, urlencoded).then((res) => {
                const allCarListData = JSON.parse(res);
                dispatch(carUpdateCreator(allCarListData))
                },
            (err) => {
                console.log(err);
            }

            )}
}

export const addCarSave = ({ brand, published, model, kilometers, province, kasko, purchaseDate, color, sellPrice, soldPrice, city, plaka, price, fuel, gear, intro, settings, description, year, responsible, responsiblePhone, images, tramer, painting, instaVideo }) => {
    //let addCarSaveUrl = `${development}/api/vehicle/save`;
    return (dispatch) => {
        let data = new FormData();
        data.append('brand', brand);
        data.append('published', published);
        data.append('model', model);
        data.append('kilometers', kilometers);
        data.append('province', province);
        data.append('city', city);
        data.append('sellPrice', sellPrice);
        data.append('purchaseDate', purchaseDate);
        data.append('color', color);
        data.append('soldPrice', soldPrice);
        data.append('plaka', plaka);
        data.append('price', price);
        data.append('fuel', fuel);
        data.append('kasko', kasko);
        data.append('gear', gear);
        data.append('intro', intro);
        data.append('settings', settings);
        data.append('description', description);
        data.append('year', year);
        data.append('responsible', responsible);
        data.append('responsiblePhone', responsiblePhone);
        data.append('tramer', tramer);
        data.append('painting', JSON.stringify(painting));
        data.append('instaVideo', instaVideo);

/*         const addCarSaveData = JSON.parse(res);
        dispatch(addCarSaveCreator(addCarSaveData.carDetails)) */
/*         for( let i = 0; i < pond.length; i++ ){
            data.append("images", pond[i].file, pond[i].file.name);
        } */
        /* axios.post(addCarSaveUrl, data).then((res) => {
        const addCarSaveData = JSON.parse(res);
        dispatch(addCarSaveCreator(addCarSaveData.carDetails))
            },
        (err) => {
            dispatch(addCarSaveError(err))
        } 
            )*/}
}

export const aboutDescription = ({_id, about, mission, vision}) => {
    
        let urlencoded = new URLSearchParams();
        urlencoded.append('_id', _id);
        urlencoded.append('about', about);
        urlencoded.append('mission', mission);
        urlencoded.append('vision', vision);

        let aboutDescriptionUrl = `${development}/api/setAbout`;
        return (dispatch) => {
                    makeRequest('POST', aboutDescriptionUrl, urlencoded, requestOptions).then((res) => {
                        const aboutDescriptionData = res.data;
                        dispatch(aboutDescriptionCreator(aboutDescriptionData))
                            },
                        (err) => {
                            console.log(err);
                        }
            )
        }
}

export const getAbout = () => {
    let getAboutUrl = `${development}/api/getAbout`;
    return (dispatch) => {
         axios.get(getAboutUrl).then((res) => {
             
                const getAboutData = res.data;
                dispatch(getAboutCreator(getAboutData))
                        },
                (err) => {
                    console.log(err);
                }
        )
    }
}
