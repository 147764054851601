const INITIAL_STATE = {
    model: "",
    modelName: [],
    brand: {},
    box: {},
    motor: {},
    brandList: [],
    boxList: [],
    motorList: [],
    updateCarId: {},
    updateCarInfos: {},
    carDelete: '',
    carDeleteSuccess: null,
    carDeleteData: {},
    login: {},
    aboutParam: {},
    getAboutParam: {},
    allCarList: [],
    carDetails: {},
    active: [],
    deActive: [],
    carSavePending: false,
    carSaveError: null

  };
  
  export default (state = INITIAL_STATE, action) => {
          switch (action.type) {   
          case 'CARSAVEPENDING':
          {
            return {...state,
            carSavePending: true,
            }
          }   
          case 'ADDCARMODEL':
          {
            return {...state,model:action.model} 
          }
          case 'VIEWCARMODEL':
          {
            return {...state,modelName:action.modelName} 
          }
          case 'GETABOUT':
          {
            return {...state,getAboutParam:action.getAboutParam} 
          }
          case 'ADDBRAND':
          {
            return {...state,brand:action.brand} 
          }
              case 'ACTIVEVEHICLE':
              {
                  return {...state,
                      active:action.active,
                      deActive: []
                  }
              }
              case 'DEACTIVEVEHICLE':
              {
                  return {...state,
                      active: [],
                      deActive:action.deActive
                  }
              }
          case 'ADDBOX':
          {
            return {...state,box:action.box} 
          }
          case 'ADDMOTOR':
          {
            return {...state,motor:action.motor} 
          }
          case 'LOGIN':
          {
            return {...state,login:action.login} 
          }
          case 'DELETECAR':
          {
            return {...state,
              carDelete: action.carDelete,
              carDeleteSuccess: action.carDeleteSuccess,
              carDeleteData: action.carDeleteData,
              allCarList: state.allCarList.filter(del => del._id !== action.carDeleteData._id)
            } 
          }
          case 'CARUPDATE':
          {
            return {...state,updateCarInfos:action.updateCarInfos} 
          }
          case 'CARUPDATEGETBYID':
          {
            return {...state,updateCarId:action.updateCarId} 
          }
          case 'VIEWCARBRAND':
          {
            return {...state,
              brandList: action.brandList
            } 
          }
          case 'VIEWCARBOX':
          {
            return {...state,
              boxList: action.boxList
            } 
          }
          case 'VIEWCARMOTOR':
          {
            return {...state,
              motorList: action.motorList
            } 
          }
          case 'ADDCARSAVE':
          {
            return {...state,
              carDetails:action.carDetails,
            } 
          }
          case 'CARSAVEERROR':
          {
            return {...state,
              carSavePending: false,
              carSaveError: action.carSaveError
            }
          }
          case 'VIEWALLCAR':
          {
            return {...state,
              allCarList:action.allCarList,
            } 
          }
          case 'ABOUT':
          {
            return {...state,
              aboutParam:action.aboutParam,
            } 
          }
          default :
          return {...state};    
      }
    };