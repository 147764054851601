import styled from 'styled-components'

const BoxViewContainer = styled.div `
    background-color: white;
    padding: 10px;
    border: 1px #ededed solid;
    border-radius: 5px;
    .addToModelsViewLabel{
        margin-bottom:15px;
            span{
                font-size: 15px;
            }   
    }
    .allModelsContainer{
        border: 1px #ededed solid;
        padding: 20px;
        border-radius: 5px;
        background-color: #f2f7fb;
        display: grid;
        grid-template-columns: repeat(10,10%);
        padding: 10px;
        text-align: center;
        align-items: center;
    line-height: 1;
        @media screen and (min-width: 280px) and (max-width: 800px){
            grid-template-columns: repeat(2, 50%);
        } 
    }
`;

export { BoxViewContainer }