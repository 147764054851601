import React, { Component } from 'react'
import {Helmet} from 'react-helmet'
import Chart from '../../Components/Chart/Loadable'

import { DashboardContainer } from './style'

import {connect} from 'react-redux';
import {news} from '../../newFeatures'
let actions = require('../../Actions');

class DashBoard extends Component {
    componentDidMount() {
        this.props.carListView();
        this.props.carBrandListView();
        this.props.allCarListView();
        this.props.deActiveVehicle();
        this.props.activeVehicle();
    }
    
    render() {
        const { modelName, brandList, allCarList, deActive, active } = this.props.borzMotor;
/*        const carLength = {
            labels: [
                'Araç Modeli',
                'Araç Marka',
                'İlan Adeti'
            ],
            datasets: [{
                data: [
                    modelName && modelName.length,
                    brandList && brandList.length,
                    allCarList && allCarList.length
                ],
                backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56'
                ],
                hoverBackgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56'
                ]
            }]
        };*/
        return (
            <DashboardContainer>
                <Helmet>
                    <title>Anasayfa | Borz Motor</title>
                </Helmet>
                {/*<Chart data={carLength}/>*/}
                <div className="whatIsNews">
                    <div className="dashBoardSectionTitle"><h2>Bilgilendirme!</h2></div>
                    <div className="itemGroups whatIsInfos">
                        <strong>Araç Ekleme; </strong>
                        <ul>
                            { news.features.aracEkleme.map(add => <li key={add}>{add}</li>) }
                        </ul>
                    </div>
                    <div className="itemGroups whatIsInfos">
                        <strong>Araç Güncelleme; </strong>
                        <ul>
                            { news.features.aracGuncelleme.map(update => <li key={update}>{update}</li>) }
                        </ul>
                    </div>
                    <div className="itemGroups whatIsInfos">
                        <strong>Neler Yeni; </strong>
                        <ul>
                            { news.features.nelerYeni.map(news => <li key={news}>{news}</li>) }
                        </ul>
                    </div>
                </div>
                <div className="dashboardInfos">
                    <div className="dashBoardSectionTitle"><h2>Sistem bilgileri</h2></div>
                    <div className="itemGroups">
                        <span>Toplam Araç:</span>
                        <strong>{allCarList && allCarList.length > 0 && allCarList.length}</strong>
                    </div>
                    <div className="itemGroups">
                        <span>Satılan Araç:</span>
                        <strong>{(allCarList && allCarList.length > 0 && allCarList.length) - (active && active.length > 0 && active.length) }</strong>
                    </div>
                    <div className="itemGroups">
                        <span>Satışta Olan Araç:</span>
                        <strong>{active && active.length > 0 && active.length }</strong>
                    </div>
                    <div className="itemGroups">
                        <span>Araç Markası:</span>
                        <strong>{brandList && brandList.length > 0 && brandList.length }</strong>
                    </div>
                    <div className="itemGroups">
                        <span>Araç Modeli:</span>
                        <strong>{modelName && modelName.length > 0 && modelName.length }</strong>
                    </div>
                </div>
            </DashboardContainer>
        )
    }
}

export default connect((state)=>{return state},actions)(DashBoard)
