import React, { Component } from 'react'
import Input from '../../Components/FormElements/Input/Loadable'
/* import Select from '../../Components/FormElements/Select/Loadable' */
import Button from '../../Components/FormElements/Button/Loadable'

import MotorsView from '../MotorView'

import { MotorSaveContainer } from './style'

import {connect} from 'react-redux';
let actions = require('../../Actions');

class CarMotorSave extends Component {
    constructor(props){
        super(props);
        this.state={
            inputValue: "",

        }
        this.inputChange = this.inputChange.bind(this)
        this.submitForm = this.submitForm.bind(this)
    }    

    componentDidMount() {
        this.props.carMotorListView();    
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.borzMotor.motor !== this.props.borzMotor.motor){
            window.location.reload()
        }
    }
    

    submitForm(e){
        e.preventDefault();
        const { inputValue } = this.state;
        this.props.addMotor(inputValue)
    }

    inputChange(e){
        let inputValue = e.target.value;
        this.setState({inputValue})
    }

    render() {
        return (
            <MotorSaveContainer>
                <form onSubmit={this.submitForm}>
                    <Input 
                        labelText="Motor Hacmi"
                        type="text"
                        inputID="motor"
                        inputHtmlFor="motor"
                        onChange={(e) => this.inputChange(e)}
                    />
                    <Button 
                        type="submit"
                        label="Motor Hacmi kaydet"
                    />
                    </form>
                <MotorsView />
            </MotorSaveContainer>
        )
    }
}
export default connect((state)=>{return state},actions)(CarMotorSave)