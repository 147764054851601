import styled from 'styled-components'

const DashboardContainer = styled.div `
width: 100%;
display: grid;
grid-template-columns: repeat(2,50%);
    max-width: 1000px;
    margin: 30px auto;
    
.dashboardInfos{
    border-left: 1px #dedede dashed;
    padding: 10px;
      .itemGroups{
      display: flex;
          justify-content: center;
          padding: 5px 0;
        span{
        display: block;
        width: 100%;
        max-width: 150px;
        }
        strong{
        display: block;
          width: 100%;
          margin-left: 15px;
          max-width: 100px;
        }
      }
}
.whatIsNews{
padding: 10px;
  .whatIsInfos{
  margin-bottom: 20px;
    strong{
    display: block;
    margin-bottom: 10px;
    }
    ul{
      margin: 0;
      padding-left: 40px;
        li{
          list-style-type: disc;
          padding: 5px 0;
        }
    }
    &:last-child{
    margin-bottom: 0;
    }
  }
}

.dashBoardSectionTitle{
margin-bottom: 10px;
h2{
font-weight: 700;
text-align: center;
text-transform: capitalize;
}
}
`;

export { DashboardContainer }