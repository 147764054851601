import React, { Component } from 'react'
import {Helmet} from 'react-helmet'
import { BrandsViewContainer } from './style'

import ModelViewComponent from '../../Components/ModelView/Loadable'
import Input from '../../Components/FormElements/Input/Loadable'

import {connect} from 'react-redux';
let actions = require('../../Actions');

class BrandsView extends Component {
    state={
        brandNameState: [],
        brandListState: []
    }
    componentDidMount() {
        this.props.carBrandListView();
    }

    filterBrands(e){
        const { brandList } = this.props.borzMotor;
        const findTo = e.target.value;
        findTo.length === 0 && this.setState({ brandNameState: [] })
        brandList && brandList.filter(car =>  car.brand === findTo && this.setState({brandNameState: [car]}))
    }
    
    render() {
        const { brandList } = this.props.borzMotor;
        const { brandNameState } = this.state;
        return (
            <BrandsViewContainer>
            <Helmet>
                <title>Araç Markaları | Borz Motor</title>
            </Helmet>
            <div className="addToModelsViewLabel">
                <span>Ekli olan araç modelleri</span>
            </div>
            <div>
                <Input
                    type="text"
                    labelText="Modeller içinde ara"
                    inputID="forModels"
                    inputHtmlFor="forModels"
                    onChange={ (e) => this.filterBrands(e) }
                />
            </div>
            <div className="allModelsContainer">
                {
                    brandNameState && brandNameState.length > 0 ? 
                    brandNameState && brandNameState.map(( {_id, brand} ) => (
                        brand && <ModelViewComponent key={_id}>
                                        {brand}
                                 </ModelViewComponent>
                    ))
                    :
                    brandList && brandList.length > 0 ? 
                    brandList && brandList.map(( {_id, brand} ) => (
                        brand && <ModelViewComponent key={_id}>
                                    {brand}
                                 </ModelViewComponent>
                    ))
                    : <div>Kayıtlı Araç Markası Bulunamadı</div>
                }
            </div>

            </BrandsViewContainer>
        )
    }
}

export default connect((state)=>{return state},actions)(BrandsView)
