import React, { Component } from 'react'
import {Helmet} from "react-helmet";
import InputComponent from '../../Components/FormElements/Input/Loadable'
import Selected from '../../Components/FormElements/Select/Loadable'
import TextEditor from '../../Components/FormElements/TextEditor/Loadable'
import ButtonComponent from '../../Components/FormElements/Button/Loadable'
import Checkbox from '../../Components/FormElements/Checkbox/Loadable'
import { development } from '../../config'
import axios from 'axios'
import { AddCarContainer, InputContainerDate, SelectContainer, TextAreaContainer, Cost, ButtonContainer, InputContainer, InputPlakaContainer, RadioInputGroup, RadioButtonHeading, Pending } from './style'
import { tramerData } from '../../Utils/tramerType'
import {connect} from 'react-redux';
import Load from '../../Utils/Loader'
import { DatePicker } from 'antd';


import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'; // Crops image
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2'

registerPlugin(FilePondPluginImageCrop, FilePondPluginImagePreview, FilePondPluginImageResize, FilePondPluginImageTransform);

let actions = require('../../Actions');


class AddCar extends Component {
    constructor(props){
        super(props);
        this.state={
            brand: '',
            published: false,
            model: '',
            motor: '',
            box: '',
            kilometers: '',
            province: '',
            city: '',
            price: '',
            fuel: '',
            plaka: '',
            gear: '',
            intro: '',
            settings: '',
            cost: '',
            purchaseDate: new Date(),
            color: '',
            description: '',
            soldPrice: '',
            sellPrice: '',
            year: '',
            responsible: '',
            responsiblePhone: '',
            warning: '',
            tramer: '',
            instaVideo: '',
            kasko: '',
            painting: {},
            images: [],
            pending: false,
            showCase: ""
        }
        this.textSettingsEditor = this.textSettingsEditor.bind(this)
        this.fileChange = this.fileChange.bind(this)
        this.tramerControl = this.tramerControl.bind(this)
        this.checkboxFunction = this.checkboxFunction.bind(this)
        this.inputChange = this.inputChange.bind(this)
        this.selectedChange = this.selectedChange.bind(this)
        this.textEditor = this.textEditor.bind(this)
        this.vehicleSaveSubmit = this.vehicleSaveSubmit.bind(this)
        this.onChangeDatePicker = this.onChangeDatePicker.bind(this)

    }
    componentDidMount() {
        this.props.carListView();
        this.props.carBrandListView();
        this.props.carMotorListView();
        this.props.carBoxListView();
    }


    vehicleSaveSubmit(e){
        e.preventDefault();
        this.setState({
            pending: true
        })
        let addCarSaveUrl = `${development}/api/vehicle/save`;
        const { brand, published, model, kilometers, province, city, purchaseDate, color, kasko, soldPrice, sellPrice, plaka, cost, motor, box, price, fuel, gear, intro, settings, description, year, responsible, responsiblePhone, tramer, painting, images, instaVideo, showCase } = this.state;
        const data = new FormData();
        data.append('brand', brand);
        data.append('published', published);
        data.append('model', model);
        data.append('kilometers', kilometers);
        data.append('province', province);
        data.append('motor', motor);
        data.append('purchaseDate', purchaseDate);
        data.append('color', color);
        data.append('soldPrice', soldPrice);
        data.append('sellPrice', sellPrice);
        data.append('box', box);
        data.append('city', city);
        data.append('price', price);
        data.append('cost', cost);
        data.append('plaka', plaka);
        data.append('fuel', fuel);
        data.append('gear', gear);
        data.append('intro', intro);
        data.append('settings', settings);
        data.append('description', description);
        data.append('year', year);
        data.append('kasko', kasko);
        data.append('responsible', responsible);
        data.append('responsiblePhone', responsiblePhone);
        data.append('tramer', tramer);
        data.append('painting', JSON.stringify(painting));
        data.append('instaVideo', instaVideo);
        data.append('showCase', showCase);
        for( let i = 0; i < images.length; i++ ){
            data.append("images", images[i], images[i].name);
        }
        axios.post(addCarSaveUrl, data)
            .then(response => {
                if(response.status === 200){
                    this.setState({
                        pending: false
                    }, () => {
                        this.props.history.push("/ilanlar")
                        this.props.addCarSave(data)
                    })
                }else{
                    return Swal.fire({
                        title: 'Kaydedilemedi! Tekrar Deneyin.',
                        imageUrl: 'https://unsplash.it/400/200',
                        imageWidth: 400,
                        imageHeight: 200
                    })
                }
            });
    }

    onChangeDatePicker(date, dateString) {
        this.setState({
            purchaseDate: dateString
        });
    }

    inputChange(e){
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        if(name === "intro" && value.length === 70){
            this.setState({ warning: '70 karakter sınırını geçemezsiniz' })
        }else{
            this.setState({warning: ''})
        }
    }

    selectedChange(e){
        const target = e;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        })
    }
    fileChange(e){
        let files = e.target.files;
        if(files.length > 0){
            this.setState({
                images: files
            })
        }
    }

    tramerControl(e){
        const target = e.target;
        const id = target.dataset.id;
        const name = target.name;

        this.setState((prevState) => ({
            painting: {...prevState.painting,
                [name]: id,
            }
        }));
    }

    textSettingsEditor(event, editor){
        const data = editor.getData();
        this.setState({
            settings: data
        })
    }

    textEditor(event, editor){
        const data = editor.getData();
        this.setState({
            description: data
        })
    }

    checkboxFunction(e, data){
        e.preventDefault();
        let published = data.checked;
        if(published){
            this.setState({ published })
        }
    }

    render() {
        const { modelName, brandList, motorList, boxList } = this.props.borzMotor;
        const { warning, pending } = this.state;
        const defaultMaskOptions = {
            prefix: '',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ',',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2, // how many digits allowed after the decimal
            integerLimit: 7, // limit length of integer numbers
            allowNegative: false,
            allowLeadingZeroes: false,
        }

        const currencyMask = createNumberMask(defaultMaskOptions)

        const yakit = [
            { value: 'Benzin', label: 'Benzin', name: 'fuel' },
            { value: 'Dizel', label: 'Dizel', name: 'fuel' },
            { value: 'Lpg & Benzin', label: 'Lpg & Benzin', name: 'fuel' },
            { value: 'Hybrit', label: 'Hybrit', name: 'fuel' }
        ];
        const year = [
            { value: '2000', label: '2000', name: 'year' },
            { value: '2001', label: '2001', name: 'year' },
            { value: '2002', label: '2002', name: 'year' },
            { value: '2003', label: '2003', name: 'year' },
            { value: '2004', label: '2004', name: 'year' },
            { value: '2005', label: '2005', name: 'year' },
            { value: '2006', label: '2006', name: 'year' },
            { value: '2007', label: '2007', name: 'year' },
            { value: '2008', label: '2008', name: 'year' },
            { value: '2009', label: '2009', name: 'year' },
            { value: '2010', label: '2010', name: 'year' },
            { value: '2011', label: '2011', name: 'year' },
            { value: '2012', label: '2012', name: 'year' },
            { value: '2013', label: '2013', name: 'year' },
            { value: '2014', label: '2014', name: 'year' },
            { value: '2015', label: '2015', name: 'year' },
            { value: '2016', label: '2016', name: 'year' },
            { value: '2017', label: '2017', name: 'year' },
            { value: '2018', label: '2018', name: 'year' },
            { value: '2019', label: '2019', name: 'year' },
            { value: '2020', label: '2020', name: 'year' },
        ];
        const vites = [
            { value: 'Manuel', label: 'Manuel', name: 'gear' },
            { value: 'Yarı Otomatik', label: 'Yarı Otomatik', name: 'gear' },
            { value: 'Otomatik', label: 'Otomatik', name: 'gear' }
        ];

        const modelMap = modelName && modelName.map(({ model }) => {
            return { value: model, label: model, name: 'model' };
        });

        const box = boxList && boxList.map(({ box }) => {
            return { value: box, label: box, name: 'box' };
        });

        const motor = motorList && motorList.map(({ motor }) => {
            return { value: motor, label: motor, name: 'motor' };
        });

        const brandMap = brandList && brandList.map(({ brand }) => {
            return { value: brand, label: brand, name: 'brand' };
        });

        return (
            <React.Fragment>
                <Helmet>
                    <title>Araç Ekle | Borz Motor</title>
                </Helmet>
                <form onSubmit={ this.vehicleSaveSubmit }>
                    { pending && <React.Fragment><Pending><Load/><span>İlan Kaydediliyor, lütfen bekleyiniz.</span></Pending></React.Fragment> }
                    <InputContainer>
                        <Checkbox
                            label="İlanı yayınla veya kaldır"
                            onChange={this.checkboxFunction}
                        />
                        <FilePond
                            ref={ref => (this.pond = ref)}
                            allowMultiple={true}
                            maxFileSize="60MB"
                            name="images"
                            allowImageCrop={true}
                            allowImageTransform={true}
                            labelIdle="Bir Dosya Seçin ya da Dosyayı Sürükleyip Bırakın!"
                            imageCropAspectRatio={'3:2'}
                            allowImageResize={true}
                            imageResizeTargetHeight={600}
                            onpreparefile={ (fileItem, file) => {
                                let joined = this.state.images.concat(file)
                                this.setState({
                                    images: joined
                                });
                            }}
                            onactivatefile={(e) => {
                                const getEl = document.querySelectorAll('.filepond--file-info-main');
                                for (let i = 0; i < getEl.length; i++) {
                                    if(getEl[i].innerText === e.file.name){
                                        getEl[i].style.cssText = "color: #34d234; font-size: 1em; font-weight: 900;";
                                    }else{
                                        getEl[i].removeAttribute("style");
                                    }
                                }
                                this.setState({
                                    showCase: e.file.name
                                })
                            }}
                        />
                    </InputContainer>
                    <InputContainerDate>
                        <div>
                            <p>Aracın ilk alındığı tarih verisini giriniz. <strong>İlan tarihinden bağımsızdır, araç envantere girdiği tarihi girmelisiniz. </strong> </p>
                        </div>
                        <DatePicker placeholder="Aracın alındığı tarihi seçin" onChange={this.onChangeDatePicker} />
                    </InputContainerDate>
                    <InputPlakaContainer>
                        <InputComponent
                            labelText="Araç Plakası"
                            type="text"
                            placeholder="Dikkat!! Benzersiz bir değer olmalıdır, Araç kart görüntüsünde sadece plaka ile görüntülenebilecektir."
                            name="plaka"
                            inputID="plaka"
                            inputHtmlFor="plaka"
                            onChange={ (e) => this.inputChange(e) }
                        />
                    </InputPlakaContainer>
                    <InputContainer>
                        <InputComponent
                            labelText="Araç Sorumlu Kişisi"
                            type="text"
                            name="responsible"
                            inputID="responsible"
                            inputHtmlFor="responsible"
                            onChange={ (e) => this.inputChange(e) }
                        />
                        <InputComponent
                            labelText="Sorumlu Kişi Telefon"
                            type="text"
                            name="responsiblePhone"
                            inputID="responsiblePhone"
                            inputHtmlFor="responsiblePhone"
                            onChange={ (e) => this.inputChange(e) }
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputComponent
                            labelText="Bulunduğu İl"
                            type="text"
                            name="city"
                            inputID="city"
                            inputHtmlFor="city"
                            onChange={ (e) => this.inputChange(e) }
                        />
                        <InputComponent
                            labelText="Bulunduğu İlçe"
                            type="text"
                            name="province"
                            inputID="province"
                            inputHtmlFor="province"
                            onChange={ (e) => this.inputChange(e) }
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputComponent
                            labelText="Kilometre"
                            type="text"
                            name="kilometers"
                            inputID="kilometers"
                            inputHtmlFor="kilometers"
                            onChange={ (e) => this.inputChange(e) }
                        />
                        <InputComponent
                            labelText="Fiyat"
                            type="text"
                            name="price"
                            inputID="price"
                            inputHtmlFor="price"
                            onChange={ (e) => this.inputChange(e) }
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputComponent
                            labelText={ warning !== '' ? warning : "Kısa açıklama" }
                            type="text"
                            name="intro"
                            maxLength="70"
                            labelStyle={ warning !== '' ? {color: '#ad4b4b'} : {}}
                            inputID="intro"
                            inputHtmlFor="intro"
                            onChange={ (e) => this.inputChange(e) }
                        />
                        <InputComponent
                            labelText={ warning !== '' ? warning : "Tramer Bilgisi" }
                            type="text"
                            name="tramer"
                            labelStyle={ warning !== '' ? {color: '#ad4b4b'} : {}}
                            inputID="tramer"
                            inputHtmlFor="tramer"
                            onChange={ (e) => this.inputChange(e) }
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputComponent
                            labelText={ warning !== '' ? warning : "Instagram Test Sürüşü Videosu" }
                            type="text"
                            name="instaVideo"
                            labelStyle={ warning !== '' ? {color: '#ad4b4b'} : {}}
                            inputID="instaVideo"
                            placeholder="örneğin; 'B-pFRNeFcTf' gibi bir kod girin "
                            inputHtmlFor="Instagram Test Sürüşü Videosu"
                            onChange={ (e) => this.inputChange(e) }
                        />
                        <InputComponent
                            labelText={ warning !== '' ? warning : "Kasko Bedeli" }
                            type="text"
                            name="kasko"
                            labelStyle={ warning !== '' ? {color: '#ad4b4b'} : {}}
                            inputID="kasko"
                            placeholder="Kasko Bedeli"
                            inputHtmlFor="kasko"
                            onChange={ (e) => this.inputChange(e) }
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputComponent
                            labelText={ warning !== '' ? warning : "Aracın Rengi" }
                            type="text"
                            name="color"
                            labelStyle={ warning !== '' ? {color: '#ad4b4b'} : {}}
                            inputID="color"
                            placeholder="Araç Rengi"
                            inputHtmlFor="color"
                            onChange={ (e) => this.inputChange(e) }
                        />
                    </InputContainer>

                    <AddCarContainer>

                        <Selected onChange={ (e) => this.selectedChange(e) } name="brand" placeholder="Araç Markası Seçimi" options={brandMap} />
                        <Selected onChange={ (e) => this.selectedChange(e) } name="model" placeholder="Markanın Model Seçimi" options={modelMap} />

                    </AddCarContainer>
                    <AddCarContainer>

                        <Selected onChange={ (e) => this.selectedChange(e) } name="motor" placeholder="Aracın Motor Hacmi" options={motor} />
                        <Selected onChange={ (e) => this.selectedChange(e) } name="box" placeholder="Araç Paketi" options={box} />

                    </AddCarContainer>
                    <SelectContainer>
                        <Selected onChange={ (e) => this.selectedChange(e) } name="fuel" placeholder="Yakıt Seçimi" options={yakit} />
                        <Selected onChange={ (e) => this.selectedChange(e) } name="gear" placeholder="Vites Seçimi" options={vites} />
                        <Selected onChange={ (e) => this.selectedChange(e) } name="year" placeholder="Araç Yılı" options={year} />
                    </SelectContainer>
                    <TextAreaContainer>
                        <RadioInputGroup>
                            <div className="RadioInputGroup">
                                <RadioButtonHeading>
                                    <li>Tramer Alanı</li>
                                    <li>Boyasız</li>
                                    <li>Boyalı</li>
                                    <li>Lokal Boyalı</li>
                                    <li>Değişen</li>
                                </RadioButtonHeading>
                            </div>

                            {
                                tramerData.length > 0 && tramerData.map(({ type, text, name, tramer }, i) => (
                                    <div className="RadioInputGroup" key={i}>
                                        <span>{text}</span>
                                        {
                                            tramer.length > 0 && tramer.map(( piece, i ) => {
                                                return <div className="radioButtonContainer" id={name} key={i}>
                                                    <InputComponent
                                                        key={i}
                                                        dataId={piece.name}
                                                        type={type}
                                                        name={name}
                                                        onChange={ (e) => this.tramerControl(e) }
                                                    />
                                                </div>
                                            })
                                        }
                                    </div>
                                ))
                            }

                        </RadioInputGroup>
                    </TextAreaContainer>
                    <TextAreaContainer>
                        <TextEditor
                            onChange={ ( event, editor ) => this.textSettingsEditor(event, editor) }
                        />
                    </TextAreaContainer>
                    <Cost>
                        <div className="costGroup">
                            <label htmlFor="cost">Araç Alış Fiyatı</label>
                            <p> Aracın alış fiyatını girmelisiniz. </p>
                            <MaskedInput
                                name="sellPrice"
                                id="sellPrice"
                                onChange={ (e) => this.inputChange(e) }
                                mask={currencyMask}
                                placeholder="Aracın Alış Fiyatını Giriniz.."
                            />
                        </div>
                        <div className="costGroup">
                            <label htmlFor="cost">Aracın Satış Fiyatı</label>
                            <p> Bu alanda Aracın satıldığı net rakamı giriniz. </p>
                            <MaskedInput
                                name="soldPrice"
                                id="soldPrice"
                                onChange={ (e) => this.inputChange(e) }
                                mask={currencyMask}
                                placeholder="Bu alanda Aracın Satıldığı Net Rakam Girilmelidir."
                            />
                        </div>
                        <div className="costGroup">
                            <label htmlFor="cost">Araç Masraf Girişi</label>
                            <p> Bu alanda Araç masraflarının toplamını girmelisiniz. <br/> Kaporta Düzeltme (1000 TL) + Pasta / Cila (500 TL) ise 1500 olarak girmelisiniz. <br/> Herhangi bir <strong>Noktalama işareti vs girmeyiniz.</strong> </p>
                            <MaskedInput
                                name="cost"
                                id="cost"
                                onChange={ (e) => this.inputChange(e) }
                                mask={currencyMask}
                                placeholder="Bu alan, araca yapılan toplam masraf tek kalemde girilmelidir. Örnek; '10000' "
                            />
                        </div>
                    </Cost>
                    <ButtonContainer>
                        <ButtonComponent
                            type="submit"
                            label="Kaydet"
                        />
                    </ButtonContainer>
                </form>
            </React.Fragment>
        )
    }
}

export default connect((state)=>{return state},actions)(AddCar)