import React, { Component } from 'react'
import Input from '../../Components/FormElements/Input/Loadable'
/* import Select from '../../Components/FormElements/Select/Loadable' */
import Button from '../../Components/FormElements/Button/Loadable'

import BrandsView from '../BrandsView'

import { CarSaveContainer } from './style'

import {connect} from 'react-redux';
let actions = require('../../Actions');

class CarBrandSave extends Component {
    constructor(props){
        super(props);
        this.state={
            inputValue: "",

        }
        this.inputChange = this.inputChange.bind(this)
        this.submitForm = this.submitForm.bind(this)
    }    

    componentDidMount() {
        this.props.carListView();    
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.borzMotor.brand !== this.props.borzMotor.brand){
            window.location.reload()
        }
    }
    

    submitForm(e){
        e.preventDefault();
        const { inputValue } = this.state;
        this.props.addBrand(inputValue)
    }

    inputChange(e){
        let inputValue = e.target.value;
        this.setState({inputValue})
    }

    render() {
        return (
            <CarSaveContainer>
                <form onSubmit={this.submitForm}>
                    <Input 
                        labelText="Araç Markası"
                        type="text"
                        inputID="addCars"
                        inputHtmlFor="addCars"
                        onChange={(e) => this.inputChange(e)}
                    />
                    <Button 
                        type="submit"
                        label="araç kaydet"
                    />
                    </form>
                <BrandsView/>
            </CarSaveContainer>
        )
    }
}
export default connect((state)=>{return state},actions)(CarBrandSave)