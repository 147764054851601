export const news = {
    features:{
        aracEkleme: [
            "Araç plakası mutlaka girilmelidir.",
            "Tramer kısmında tramer bulunmuyor yazısını görmek için değer olarak '0' girilmelidir.",
            "İlan görselleri maksimum 25 adet olmalıdır.",
            "Araç masrafı ve alım tutarı mutlaka girilmelidir.",
        ],
        aracGuncelleme: [
            "Araç satış tarihi mutlaka girilmelidir.",
            "Araç satış tutarı mutlaka girilmelidir",
        ],
        nelerYeni: [
            "Plaka ekleme seçeneği",
            "Araç rengi girebilme seçeneği",
            "Kasko tutarı girebilme",
            "Araç alış ve satış tarihlerini el ile girebilme",
            "Masraf, alış, satış fiyatlarını girebilme"
        ]
    }
}