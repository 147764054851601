import React from 'react';
import { ActiveVehicleComponentContainer } from './style'
import { Button } from 'antd';

const ActiveMyVehicle = React.memo(function ActiveVehicle({ active, clickActive, clickDeActive, sell, sold }){
    return (
        <ActiveVehicleComponentContainer>
            <Button onClick={clickActive} name={sell} type={ active ? "primary" : "info" } style={ { marginRight: '10px' } }>Satışta olanlar</Button>
            <Button onClick={clickDeActive} name={sold} type={ !active ? "primary" : "info" }>Satılanlar</Button>
        </ActiveVehicleComponentContainer>
    );
});

export {ActiveMyVehicle}