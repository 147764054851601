import React, { Component } from 'react'
import Input from '../../Components/FormElements/Input/Loadable'
/* import Select from '../../Components/FormElements/Select/Loadable' */
import Button from '../../Components/FormElements/Button/Loadable'

import BoxView from '../BoxView'

import { CarBoxContainer } from './style'

import {connect} from 'react-redux';
let actions = require('../../Actions');

class BoxContainer extends Component {
    constructor(props){
        super(props);
        this.state={
            inputValue: "",

        }
        this.inputChange = this.inputChange.bind(this)
        this.submitForm = this.submitForm.bind(this)
    }    

    componentDidMount() {
        this.props.carBoxListView();    
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.borzMotor.box !== this.props.borzMotor.box){
            window.location.reload()
        }
    }
    

    submitForm(e){
        e.preventDefault();
        const { inputValue } = this.state;
        this.props.addBox(inputValue)
    }

    inputChange(e){
        let inputValue = e.target.value;
        this.setState({inputValue})
    }

    render() {
        return (
            <CarBoxContainer>
                <form onSubmit={this.submitForm}>
                    <Input 
                        labelText="Araç Paketi"
                        type="text"
                        inputID="box"
                        inputHtmlFor="box"
                        onChange={(e) => this.inputChange(e)}
                    />
                    <Button 
                        type="submit"
                        label="Paket kaydet"
                    />
                    </form>
                <BoxView/>
            </CarBoxContainer>
        )
    }
}
export default connect((state)=>{return state},actions)(BoxContainer)