import React, { Component } from 'react'
import AboutUs from '../../Components/About/Loadable'
import Button from '../../Components/FormElements/Button/Loadable'
import { AboutUsSeperator, AboutContainer } from './style'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

import {connect} from 'react-redux';
let actions = require('../../Actions');

class About extends Component {
    
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitSingleForm = this.handleSubmitSingleForm.bind(this);
        this.state={
            _id: '',
            about: '',
            vision: '',
            mission: ''
        }
    }

    componentDidMount() {
        this.props.getAbout()

    }

    componentDidUpdate(prevProps, prevState) {
        const { about, mission, vision } = this.props.borzMotor.getAboutParam[0] || {}; 
        if(prevProps.borzMotor.getAboutParam[0] !== this.props.borzMotor.getAboutParam[0]){
            this.setState({
                about: about,
                mission: mission,
                vision: vision
            })
        }
    }
    

    handleSubmitSingleForm(e){
        e.preventDefault();
        const id = e.target.id;
        this.setState({_id: id}, () => {
            const state = this.state;
            if(state && ( state.about !== '' && state.vision !== '' && state.mission !== '')){
                if(state && ( state["about"] && state["about"].length >= 100 && state["vision"] && state["vision"].length >= 100 && state["mission"] && state["mission"].length >= 100) ){
                    this.props.aboutDescription(state);
                    Swal.fire({
                        title: 'Kayıt Başarılı',
                        imageUrl: 'https://unsplash.it/400/200',
                        imageWidth: 400,
                        imageHeight: 200
                      })
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                }else{
                    Swal.fire({
                        title: 'Kayıt Başarısız',
                        text: '100 karakterden az içerik giremezsiniz',
                        imageUrl: 'https://unsplash.it/400/200',
                        imageWidth: 400,
                        imageHeight: 200
                      })
                }
            }else{
                Swal.fire({
                    title: 'Boş Alan Uyarısı',
                    text: 'Hakkında, Vizyon ve Misyon Alanlarının tamamı doldurulmalıdır',
                    imageUrl: 'https://unsplash.it/400/200',
                    imageWidth: 400,
                    imageHeight: 200
                  })
            }

        });
    }

    handleChange(e){
        const target = e.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value,
        });
    }

    render() {
        const { about, vision, mission} = this.state;
        const { _id } = this.props.borzMotor.getAboutParam[0] || '';
        return (
            <AboutContainer id={_id} onSubmit={(e) => this.handleSubmitSingleForm(e)}>
               <AboutUsSeperator>
                    <label htmlFor="about">Hakkında</label>
                    <AboutUs 
                        placeholder="Hakkında Mesajı"
                        name="about"
                        value={about}
                        onchange={ (e) => this.handleChange(e) }
                    />
                </AboutUsSeperator>
                <AboutUsSeperator>
                    <label htmlFor="vision">Vizyon</label>
                    <AboutUs 
                        placeholder="Vizyon Mesajı"
                        name="vision"
                        value={vision}
                        onchange={ (e) => this.handleChange(e) }
                    />
                </AboutUsSeperator>
                <AboutUsSeperator>
                    <label htmlFor="mission">Misyon</label>
                    <AboutUs 
                        placeholder="Misyon Mesajı"
                        name="mission"
                        value={mission}
                        onchange={ (e) => this.handleChange(e) }
                    />
                </AboutUsSeperator>
                <Button type="submit" label="Gönder"/>
            </AboutContainer>
        )
    }
}

export default connect((state)=>{return state},actions)(About)