import React, { Component } from 'react'
import {Helmet} from 'react-helmet'
import Card from '../../Components/Card/Loadable'
import placeholderImage from '../../Images/placeholder-single.png'
import { AllCar, TotalAmount } from './style'
import {ActiveMyVehicle} from '../../Components/ActiveVehicle'
//import Slider from '../../Components/Slider/Loadable'
//import { sliderSettings } from '../../Components/Slider/sliderSettings'
import CarsUpdate from '../CarsUpdate'

import { development } from '../../config'

import {connect} from 'react-redux';
let actions = require('../../Actions');

class CarsView extends Component {
    constructor(props){
        super(props);
        this.state={
            updateCarInfos: '',
            activeState: true
        }
        this.editItem = this.editItem.bind(this);
        this.findToActiveVehicle = this.findToActiveVehicle.bind(this);
        this.findToDeActiveVehicle = this.findToDeActiveVehicle.bind(this);
    }
    componentDidMount() {
        const { deActive } = this.props.borzMotor;
        if(deActive && deActive.length > 0){
            this.props.deActiveVehicle();
        }else{
            this.props.activeVehicle();
        }
    }
    
    editItem(e) {
        //this.props.carUpdateGetId(e.target.dataset.id);
        this.setState({
            updateCarInfos: e.target.dataset.id
        })
    }

    deletItem(e) {
        this.props.carDelete(e.target.dataset.id)
        /* window.location.reload(); */
    }

    componentDidUpdate(prevProps, prevState) {
        const { updateCarInfos } = this.props.borzMotor;
        if(updateCarInfos !== prevProps.borzMotor.updateCarInfos){
            this.setState({ updateCarInfos })
        }
    }

    findToActiveVehicle(){
        this.props.activeVehicle();
        this.setState({
            activeState: true
        });
    }

    findToDeActiveVehicle(){
        this.props.deActiveVehicle();
        this.setState({
            activeState: false
        });
    }

    render() {
        const { active, deActive } = this.props.borzMotor;
        const { activeState } = this.state;
        return (
           <React.Fragment>
               <ActiveMyVehicle active={ activeState } clickDeActive={ this.findToDeActiveVehicle } clickActive={ this.findToActiveVehicle }/>
                       <TotalAmount>{ active && active.length > 0 ? <React.Fragment><strong>{active.length} </strong><span> Adet Araç Listelenmektedir</span></React.Fragment> : <React.Fragment><strong>{deActive.length}</strong><span> Adet Araç listelenmektedir</span></React.Fragment> }</TotalAmount>
               <AllCar>
                   <Helmet>
                       <title>İlanlar | Borz Motor</title>
                   </Helmet>

                   {
                       active && active.length > 0 &&
                       active.map(({ _id, images, brand, plaka, model, price, published, intro, kilometers, fuel, gear }) => {
                        return <Card
                            key={_id}
                            plaka={plaka && plaka }
                            filterClass={ published && published ? '' : 'filtered' }
                            id={_id}
                            published={ published && published ? 'İlan Yayında' : 'İlan Yayında Değil'}
                            deleteForItem={ (e) => this.deletItem(e) }
                            editForItem={ (e) => this.editItem(e) }
                            images={ images && images.length > 0 ? <div><img src={development + "/" + images[0].name } alt=""/></div> : <div><img src={placeholderImage} alt=""/></div>}
                            brand={brand}
                            price={price}
                            model={model}
                            intro={intro}
                            km={kilometers}
                            fuel={fuel}
                            gear={gear}
                        >
                            <CarsUpdate id={ _id && _id }/>
                        </Card>
                    })
                   }

                   {
                       deActive && deActive.length > 0 &&
                       deActive.map(({ _id, images, brand, plaka, model, price, published, intro, kilometers, fuel, gear }) => {
                           return <Card
                               key={_id}
                               plaka={plaka && plaka }
                               filterClass={ published && published ? '' : 'filtered' }
                               id={_id}
                               published={ published && published ? 'İlan Yayında' : 'İlan Yayında Değil'}
                               deleteForItem={ (e) => this.deletItem(e) }
                               editForItem={ (e) => this.editItem(e) }
                               images={ images && images.length > 0 ? <div><img src={development + "/" + images[0].name } alt=""/></div> : <div><img src={placeholderImage} alt=""/></div>}
                               brand={brand}
                               price={price}
                               model={model}
                               intro={intro}
                               km={kilometers}
                               fuel={fuel}
                               gear={gear}
                           >
                               <CarsUpdate id={ _id && _id }/>
                           </Card>
                       })
                   }

               </AllCar>
           </React.Fragment>
        )
    }
}
export default connect((state)=>{return state},actions)( CarsView )

